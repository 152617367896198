<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  mounted() {
    defineSlice({
      name: {
        label: 'Introduction Two Column',
        group: 'Hyatt',
        layouts: ['HyattZilara', 'HyattZiva'],
      },
      tags: ['Columns', 'Wysiwyg'],
      description:
        'Huge scripty title with an introduction and a background graphic from a list of selectable SVGs',
      fields: {
        title: { type: 'wysiwyg', label: 'Title', editorLabel: true },
        subtitle: { type: 'wysiwyg', label: 'Sub-Title' },
        introduction: { type: 'wysiwyg', label: 'Introduction' },
      },
      templates: [
        {
          label: 'Introduction Two Column',
          fields: {
            bgsvg: { value: 'spiral-coral-web' },
            introduction: {
              value:
                '<div>Situated on the widest stretch of beach in Cancun in the heart of the famous Hotel Zone, Hyatt Zilara Cancun welcomes you to an adults-only oasis of all-inclusive, oceanfront luxury.&nbsp;<\/div>',
            },
            subtitle: {
              value: '<p><strong>All-Inclusive<br>Exclusively for Adults<\/strong><\/p>',
            },
            title: { value: '<div>Luxury<\/div>' },
          },
        },
      ],
    })
  },
}
</script>

<template>
  <div class="flex justify-center py-8 md:p-8">
    <div
      class="py-8 md:p-12 container text-center flex flex-col md:flex-row justify-between items-center"
    >
      <div class="flex-none text-center md:text-left relative w-1/2">
        <div
          class="title font-cursive font-light mb-1 md:absolute z-0 left-0 right-0 -mt-8 text-zilara-cancun-grey text-5xl md:text-8xl md:ml-14"
          v-html="fields.title.value"
        />
        <div class="font-thin mb-4 relative z-10" v-html="fields.subtitle.value" />
      </div>
      <div
        class="wysiwyg px-8 text-center md:text-left leading-loose"
        v-html="fields.introduction.value"
      />
    </div>
  </div>
</template>
