<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    showColOne() {
      return this.fields.column1list.value || this.fields.column1photo.value?.length
    },
    showColTwo() {
      return this.fields.column2list.value || this.fields.column2photo.value?.length
    },
    showColThree() {
      return this.fields.column3list.value || this.fields.column3photo.value?.length
    },
    showColFour() {
      return this.fields.column4list.value || this.fields.column4photo.value?.length
    },
    colStyle() {
      if (this.$voix.breakpoint.value !== 'mobile') {
        return {
          'padding-bottom': this.bottomPadding(this.fields.column1photo.value),
        }
      }
      return null
    },
    colSize() {
      let size = 0

      if (this.fields.column1list.value)
        size += 1
      if (this.fields.column2list.value)
        size += 1
      if (this.fields.column3list.value)
        size += 1
      if (this.fields.column4list.value)
        size += 1

      return {
        'md:w-full': size === 1,
        'md:w-1/2': size === 2,
        'md:w-1/3': size === 3,
        'md:w-1/4': size === 4,
      }
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Columns And Pictures', group: 'global', layouts: ['*'] },
      tags: ['Columns', 'Images'],
      fields: {
        title: { type: 'text', label: 'Title', editorLabel: true, name: 'title' },
        titleClass: {
          type: 'select',
          label: 'Title Style',
          allowNull: true,
          options: {
            sans: 'Sans-Serif',
            serif: 'Serif',
            cursive: 'Cursive',
            jewelcursive: 'Jewel Cursive',
          },
          name: 'titleClass',
        },
        introduction: { type: 'wysiwyg', label: 'Introduction', name: 'introduction' },
        imagesAbove: {
          type: 'checkbox',
          label: 'Images Above Column?',
          default: false,
          name: 'imagesAbove',
        },
        column1list: { type: 'wysiwyg', label: 'Column One List', name: 'column1list' },
        column1photo: {
          type: 'media',
          label: 'Column One Image',
          breakpoints: {
            default: { width: 375, height: 200 },
            lg: { width: 563, height: 300 },
          },
          name: 'column1photo',
        },
        column2list: {
          type: 'wysiwyg',
          label: 'Column Two List',
          name: 'column2list',
        },
        column2photo: {
          type: 'media',
          label: 'Column Two Image',
          breakpoints: {
            default: { width: 375, height: 200 },
            lg: { width: 563, height: 300 },
          },
          name: 'column2photo',
        },
        column3list: {
          type: 'wysiwyg',
          label: 'Column Three List',
          name: 'column3list',
        },
        column3photo: {
          type: 'media',
          label: 'Column Three Image',
          breakpoints: {
            default: { width: 375, height: 200 },
            lg: { width: 563, height: 300 },
          },
          name: 'column3photo',
        },
        column4list: {
          type: 'wysiwyg',
          label: 'Column Four List',
          name: 'column4list',
        },
        column4photo: {
          type: 'media',
          label: 'Column Four Image',
          breakpoints: {
            default: { width: 375, height: 200 },
            lg: { width: 563, height: 300 },
          },
          name: 'column4photo',
        },
      },
      slots: [],
    })

    document.querySelectorAll('.list-disc').forEach((el) => {
      el.querySelectorAll('li').forEach((li) => {
        li.style.marginBottom = '16px'
      })
    })
  },
  methods: {
    bottomPadding(image) {
      if (!this.fields.imagesAbove.value && image?.length > 0)
        return '220px'

      return ''
    },
  },
}
</script>

<template>
  <div class="flex justify-center columns-and-pictures">
    <div class="py-10 md:p-10 container text-center flex flex-col items-center">
      <h3
        v-if="fields.title.value"
        class="uppercase px-2 mb-8 text-3xl"
        :class="[`font-${fields.titleClass.value || 'sans'}`]"
        v-html="fields.title.value"
      />
      <div
        v-if="fields.introduction.value"
        class="px-2 wysiwyg md:w-2/3 mb-12"
        v-html="fields.introduction.value"
      />

      <div
        class="flex justify-between flex-grow items-stretch flex-col md:flex-row w-full"
      >
        <div
          v-if="showColOne"
          :class="colSize"
          class="md:mx-4 relative flex-grow flex flex-col md:flex-col-reverse justify-between"
        >
          <VoixMedia
            v-if="fields.column1photo.value && fields.column1photo.value?.length"
            v-slot="slotProps"
            :field="fields.column1photo"
            :background="true"
          >
            <div
              class="mt-6 md:mt-0"
              :class="{
                'bg-cover bg-center': !fields.imagesAbove
                  .value,
              }"
              :style="{
                backgroundImage: `url(${slotProps.image})`,
              }"
              style="height: 200px"
            />
          </VoixMedia>

          <VoixWysiwyg
            v-if="fields.column1list"
            :field="fields.column1list"
            class="m-4 md:mt-0 mx-8 md:mx-0 prose-li:list-disc list-disc text-left marker:text-black"
          />
        </div>

        <div
          v-if="showColTwo"
          :class="colSize"
          class="md:mx-4 relative flex-grow flex flex-col md:flex-col-reverse justify-between"
        >
          <VoixMedia
            v-if="fields.column2photo.value && fields.column2photo.value?.length"
            v-slot="slotProps"
            :field="fields.column2photo"
            :background="true"
          >
            <div
              class="mt-6 md:mt-0"
              :class="{
                'bg-cover bg-center': !fields.imagesAbove
                  .value,
              }"
              :style="{
                backgroundImage: `url(${slotProps.image})`,
              }"
              style="height: 200px"
            />
          </VoixMedia>

          <VoixWysiwyg
            v-if="fields.column2list.value"
            :field="fields.column2list"
            class="m-4 md:mt-0 mx-8 md:mx-0 prose-li:list-disc list-disc text-left marker:text-black"
          />
        </div>
        <div
          v-if="showColThree"
          :class="colSize"
          class="md:mx-4 relative flex-grow flex flex-col md:flex-col-reverse justify-between"
        >
          <VoixMedia
            v-if="fields.column3photo.value && fields.column3photo.value?.length"
            v-slot="slotProps"
            :field="fields.column3photo"
            :background="true"
          >
            <div
              class="mt-6 md:mt-0"
              :class="{
                'bg-cover bg-center': !fields.imagesAbove
                  .value,
              }"
              :style="{
                backgroundImage: `url(${slotProps.image})`,
              }"
              style="height: 200px"
            />
          </VoixMedia>

          <VoixWysiwyg
            v-if="fields.column3list.value"
            :field="fields.column3list"
            class="m-4 md:mt-0 mx-8 md:mx-0 prose-li:list-disc list-disc text-left marker:text-black"
          />
        </div>
        <div
          v-if="showColFour"
          :class="colSize"
          class="md:mx-4 relative flex-grow flex flex-col md:flex-col-reverse justify-between"
        >
          <VoixMedia
            v-if="fields.column4photo.value && fields.column4photo.value?.length"
            v-slot="slotProps"
            :field="fields.column4photo"
            :background="true"
          >
            <div
              class="mt-6 md:mt-0"
              :class="{
                'bg-cover bg-center': !fields.imagesAbove
                  .value,
              }"
              :style="{
                backgroundImage: `url(${slotProps.image})`,
              }"
              style="height: 200px"
            />
          </VoixMedia>
          <VoixWysiwyg
            v-if="fields.column4list.value"
            :field="fields.column4list"
            class="m-4 md:mt-0 mx-8 md:mx-0 prose-li:list-disc list-disc text-left marker:text-black"
          />
        </div>
      </div>
    </div>
  </div>
</template>
