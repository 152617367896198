<script>
import anime from 'animejs/lib/anime.es.js'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      animationDuration: 1500,
      counters: {
        numberOfRestaurants: 0,
        numberOfBars: 0,
        numberOfLounges: 0,
      },
    }
  },
  computed: {
    containerStyles() {
      // hide background image on small
      if (this.$voix.breakpoint.isLessThan('md'))
        return { backgroundImage: 'none' }

      return {}
    },
  },
  mounted() {
    defineSlice({
      fields: {
        image: {
          type: 'media',
          label: 'Background Image',
          breakpoints: {
            default: { width: 625, height: 625 },
            lg: { width: 1000, height: 850 },
          },
        },
        title: { type: 'text', label: 'Title', editorLabel: true },
        description: { type: 'wysiwyg', label: 'Description' },
        restaurants: { type: 'number', label: '# of Restaurants' },
        bars: { type: 'number', label: '# of Bars' },
        lounges: { type: 'number', label: '# of Lounges' },
        includedItems: { type: 'wysiwyg', label: 'Included Items' },
      },
      name: { label: 'All Included', group: 'Playa Collection', layouts: ['*'] },
      tags: ['Content', 'Wysiwyg'],
      templates: [
        {
          label: 'All Included',
          fields: {
            description: {
              value:
                '<p>Here at&nbsp;The Yucatan, enjoy the appeal of a boutique hotel and the luxuries of an all-inclusive resort where everything is at your fingertips. Spacious rooms and suites, stylishly appointed and luxuriously equipped, plus reservation-free dining, room service &amp; thoughtful amenities are all included in your stay.<\/p><p><strong>Want even more out of your all-inclusive vacation?<\/strong> Upgrade to All-Inclusive Plus and receive exclusive access to all amenities at Hilton Playa del Carmen, our sister all-inclusive adult resort, newly renovated and rated Four Diamonds by AAA for upscale style and amenities with the right touch of service. <br><br><a href="\/the-yucatan-resort-playa-del-carmen\/all-inclusive-plus-experience" rel="noopener noreferrer nofollow">FIND OUT MORE<\/a><\/p><p><\/p>',
            },
            image: {
              value:
                'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/rooftop-couple-wide-4.jpg',
            },
            includedItems: {
              value:
                '<p>Unlimited Dining<\/p><p>Endless Drinks<\/p><p>Minibar stocked daily with<br>water, beer, and soft drinks<\/p><p>Unlimited cocktails and wine<\/p><p>Infinity Rooftop Pool<\/p><p>Room Service<\/p><p>Free Wi-Fi<\/p><p>Fitness Center<\/p><p>Weekly rooftop DJ sessions<\/p>',
            },
            title: { value: 'IT\'S ALL INCLUDED' },
          },
        },
      ],
    })
  },
  methods: {
    onVisibilityChange(entry) {
      if (entry.intersectionRatio * 100 > 0) {
        anime({
          targets: this.counters,
          numberOfRestaurants: this.fields.restaurants.value,
          round: 1,
          duration: this.animationDuration,
          easing: 'linear',
        })
        anime({
          targets: this.counters,
          numberOfBars: this.fields.bars.value,
          round: 1,
          duration: this.animationDuration,
          delay: this.animationDuration,
          easing: 'linear',
        })
        anime({
          targets: this.counters,
          numberOfLounges: this.fields.lounges.value,
          round: 1,
          duration: this.animationDuration,
          delay: this.animationDuration * 2,
          easing: 'linear',
        })
        anime({
          targets: ['.restaurant-label', '.bars-label', '.lounges-label'],
          opacity: 1,
          duration: 3000,
          easing: 'easeOutExpo',
          delay: anime.stagger(this.animationDuration),
        })
      }
    },
  },
}
</script>

<template>
  <div class=" px-4 lg:px-0 py-12 bg-abs-white">
    <VoixMedia v-slot="slotProps" :field="fields.image" background>
      <div
        v-voix-visibility="{ callback: onVisibilityChange, threshold: [0, 0.25] }"
        class="w-full bg-cover bg-center"
        :style="
          Object.assign({ backgroundImage: `url(${slotProps.image})` }, containerStyles)
        "
      >
        <div class="flex justify-center">
          <div class="container flex justify-end">
            <div class="p-4 p-0 w-full md:w-2/5">
              <h3 class="text-3xl font-sans-lato font-light mb-4">
                {{ fields.title.value }}
              </h3>
              <div v-html="fields.description.value" />
              <div class="flex justify-between">
                <div v-if="fields.restaurants.value" class="text-center">
                  <div
                    class="text-collection-teal font-serif-times font-bold"
                    style="font-size: 92px"
                  >
                    {{ counters.numberOfRestaurants }}
                  </div>
                  <div class="pb-2 px-4 w-full flex justify-center">
                    <div class="border-b border-grey-dark" style="width: 40px" />
                  </div>
                  <div
                    class="text-xs uppercase font-thin leading-loose opacity-0 restaurant-label"
                  >
                    Restaurants
                  </div>
                </div>
                <div v-if="fields.bars.value" class="text-center">
                  <div
                    class="text-collection-teal font-serif-times font-bold"
                    style="font-size: 92px"
                  >
                    {{ counters.numberOfBars }}
                  </div>
                  <div class="pb-2 px-4 w-full flex justify-center">
                    <div class="border-b border-grey-dark" style="width: 40px" />
                  </div>
                  <div
                    class="text-xs uppercase font-thin leading-loose opacity-0 bars-label"
                  >
                    Bars
                  </div>
                </div>
                <div v-if="fields.lounges.value" class="text-center">
                  <div
                    class="text-collection-teal font-serif-times font-bold"
                    style="font-size: 92px"
                  >
                    {{ counters.numberOfLounges }}
                  </div>
                  <div class="pb-2 px-4 w-full flex justify-center">
                    <div class="border-b border-grey-dark" style="width: 40px" />
                  </div>
                  <div
                    class="text-xs uppercase font-thin leading-loose opacity-0 lounges-label"
                  >
                    Lounges
                  </div>
                </div>
              </div>
              <div
                class="pt-8 text-sm"
                style="columns: 2; column-gap: 30px"
                v-html="fields.includedItems.value"
              />
            </div>
          </div>
        </div>
      </div>
    </VoixMedia>
  </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Special+Elite');
:deep(.story) {
	font-family: 'Special Elite', cursive;
	color: #FFFCF4;
}
</style>
