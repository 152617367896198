<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  mounted() {
    defineSlice({
      name: { label: 'Title Paragraph', group: 'global', layouts: ['*'] },
      tags: ['Title', 'Paragraph', 'Wysiwyg'],
      fields: {
        title: {
          group: 'Title',
          type: 'text',
          label: 'Title',
          editorLabel: true,
          enabled: true,
        },
        // titleSize came from devise one so i'm leaving the name alone
        titleSize: {
          group: 'Title',
          type: 'select',
          label: 'Title Tag',
          options: { h1: 'h1', h2: 'h2', h3: 'h3', h4: 'h4', h5: 'h5', h6: 'h6' },
          default: 'h3',
        },
        titleDisplaySize: {
          type: 'select',
          label: 'Title Size',
          group: 'Title',
          options: {
            'text-sm': 'Small',
            'text-base': 'Base',
            'text-lg': 'Large',
            'text-xl': 'XL',
            'text-2xl': '2XL',
            'text-3xl': '3XL',
            'text-4xl': '4XL',
            'text-5xl': '5XL',
          },
        },
        pretitle: { type: 'text', label: 'Pre-title', enabled: false },
        subtitle: { type: 'text', label: 'Sub-title', enabled: false },
        introduction: { type: 'wysiwyg', label: 'Introduction' },
        introductionSize: {
          type: 'select',
          label: 'Introduction Size',
          options: {
            'text-sm': 'Small',
            'text-base': 'Base',
            'text-lg': 'Large',
            'text-xl': 'XL',
            'text-2xl': 'XXL',
          },
          default: 'text-base',
        },
        narrow: { type: 'checkbox', label: 'Narrow Paragraph', default: false },
        twoColumns: {
          type: 'checkbox',
          label: 'Title and Description in columns?',
          default: false,
        },
        position: {
          type: 'select',
          label: 'Position',
          options: { left: 'Left', center: 'Center', right: 'Right' },
          default: 'center',
        },
      },
      slots: [],
    })
  },
}
</script>

<template>
  <div>
    <div
      class="flex title-paragraph relative"
      :class="{
        'justify-center': fields.position.value === 'center',
        'justify-start': fields.position.value === 'left',
        'justify-end': fields.position.value === 'right',
      }"
    >
      <div
        class="py-8 p-8 md:p-12 container mx-auto grid gap-8 relative z-10"
        :class="[
          fields.position.value,
          {
            'md:grid-cols-2': fields.twoColumns.value,
            'justify-center': fields.position.value === 'center',
            'justify-start': fields.position.value === 'left',
            'justify-end': fields.position.value === 'right',
          },
        ]"
      >
        <div
          v-if="fields.title.enabled"
          class="title flex justify-center flex-col"
          :class="{
            'max-w-[500px]': fields.narrow.value,
            'items-center text-center': fields.position.value === 'center',
            'items-start text-left': fields.position.value === 'left',
            'items-end text-right': fields.position.value === 'right',
          }"
        >
          <VoixWysiwyg
            class="font-bold uppercase mb-2 text-xs"
            :field="fields.pretitle"
          />

          <div v-if="fields.title.value">
            <h1 v-if="fields.titleSize.value === 'h1'" :class="fields.titleDisplaySize.value">
              {{ fields.title.value }}
            </h1>
            <h2 v-if="fields.titleSize.value === 'h2'" :class="fields.titleDisplaySize.value">
              {{ fields.title.value }}
            </h2>
            <h3 v-if="fields.titleSize.value === 'h3'" :class="fields.titleDisplaySize.value">
              {{ fields.title.value }}
            </h3>
            <h4 v-if="fields.titleSize.value === 'h4'" :class="fields.titleDisplaySize.value">
              {{ fields.title.value }}
            </h4>
            <h5 v-if="fields.titleSize.value === 'h5'" :class="fields.titleDisplaySize.value">
              {{ fields.title.value }}
            </h5>
            <h6 v-if="fields.titleSize.value === 'h6'" :class="fields.titleDisplaySize.value">
              {{ fields.title.value }}
            </h6>
          </div>

          <VoixWysiwyg
            class="font-bold uppercase mb-2 text-base"
            :field="fields.subtitle"
          />
        </div>
        <VoixWysiwyg
          v-if="fields.introduction.value"
          class="prose-p:mb-3 flex items-center font-light"
          :class="[
            fields.introductionSize.value,
            {
              'max-w-[500px]': fields.narrow.value,
              'justify-center text-center': fields.position.value === 'center',
              'justify-start text-left': fields.position.value === 'left',
              'justify-end text-right': fields.position.value === 'right',
            },
          ]"
          :field="fields.introduction"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Special+Elite');

:deep(.quote) {
	font-family: 'Special Elite', cursive;
	color: #3f3f5a;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

:deep(blockquote) {

	font-size: 20px;
	max-width: 1400px;
	line-height: 1.4;
	position: relative;

}

:deep(blockquote:before),
:deep(blockquote:after) {
	position: absolute;
	color: #f1efe6;
	font-size: 5rem;
	width: 3rem;
	height: 3rem;
}

:deep(blockquote:before) {
	content: '“';
	left: -4rem;
	top: 1rem;
}

:deep(blockquote:after) {
	content: '”';
	right: -3rem;
	bottom: -3rem;
}

:deep(cite) {
	line-height: 1.5;
	text-align: right;
}

:deep(.mobilebutcher) {
  display: none;
}

@media screen and (max-width: 500px) {
  :deep(.mobilebutcher) {
    display: inline-block;
  }
}
</style>
